import styled from '@emotion/styled'
import { format } from 'date-fns'
import { Box, Button, Modal, Spacer } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { MeasurementsFields, SessionItem } from 'custom/valentino/api/types'
import ImagesGallery from './ImagesGallery'

interface Props {
  visible: boolean
  item?: SessionItem
  measurementsFields?: MeasurementsFields[]
  onClose: () => void
}

export default function InboundWorkstationQualityControlModal({ onClose, visible, item, measurementsFields }: Props) {
  const measurements = measurementsFields?.map((m) => m.fields).flat(1)

  const getStatus = () => {
    let status = '---'
    switch (item?.status) {
      case 'OK_REPAIR':
        status = 'OK RIPARAZIONE'
        break
      case 'OK_RISC':
        status = 'RISCHIATO'
        break
      case 'OK_WORKER':
        status = 'OK FORNITORE'
        break
      case 'OK_VALDAGNO':
        status = 'OK VALDAGNO'
        break
      case 'OK_LINING':
        status = 'OK FODERA'
        break
      case 'KO_WORKER':
        status = 'KO FORNITORE'
        break
      case 'KO_VALDAGNO':
        status = 'KO VALDAGNO'
        break
      case 'KO_LINING':
        status = 'KO FODERA'
        break
      case 'SECOND_VALDAGNO':
        status = 'SECONDA SCELTA'
        break
      case 'RESO_RICHIESTO_CQ':
        status = 'RICHIESTO CQ'
        break
      case 'RESO_OK':
        status = 'RESO OK'
        break
      default:
        status = '---'
        break
    }
    return status
  }

  const getAttribute = (key: string) => {
    return item?.attributes?.find((attr) => attr.key === key)?.value || '---'
  }

  const renderMeasurements = () => {
    if (!item?.measurements) return
    return Object.keys(item?.measurements).map((key, index) => {
      const measureField = measurements?.find((m) => m.key === key)
      return (
        <Box row key={index} bg={index % 2 === 0 ? '#E2E2E2' : '#fff'} style={{ padding: 5 }}>
          <Label>{measureField?.label}</Label>
          <Value>{item?.measurements[key]}</Value>
        </Box>
      )
    })
  }

  return (
    <Modal onClose={onClose} visible={visible} size={'6xl'}>
      <Title>Dettaglio Item Sessione</Title>
      <Spacer height="40" />
      <Title>Dettaglio</Title>
      <Spacer height="10" />
      <Box vcenter>
        <Box row>
          <Label>Stato</Label>
          <Value>{getStatus()}</Value>
        </Box>
        <Box row>
          <Label>Creato</Label>
          {!!item?.creationDate ? format(new Date(item?.creationDate), 'dd/MM/yyyy HH:mm') : '---'}
        </Box>
        <Box row>
          <Label>EPC</Label>
          <Value>{item?.identifierCode || '---'}</Value>
        </Box>
        <Box row>
          <Label>Modello</Label>
          <Value>{getAttribute('material') || '---'}</Value>
        </Box>
      </Box>
      <Spacer height="30" />
      <Box row>
        <Box flex>
          <Title>Misure</Title>
          {renderMeasurements()}
        </Box>
      </Box>
      <Spacer height="30" />
      <Box>
        <Title>Immagini</Title>
        <ImagesGallery images={item?.images || []} />
      </Box>
      <Spacer height="30" />
      <Box>
        <Title>Note</Title>
        <span>{item?.notes}</span>
      </Box>
      <Box center vcenter>
        <Button title={__(T.misc.close)} onClick={onClose} />
      </Box>
    </Modal>
  )
}

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`
const Value = styled.div`
  margin-left: 8px;
`

const Label = styled.div`
  font-weight: bold;
  min-width: 100px;
`
