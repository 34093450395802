import { Component } from 'react'
import {
  AntennaButton,
  Box,
  Button,
  FormSchemaTextField,
  FullLoadingLayer,
  Page,
  Spacer,
  Table,
} from 'stylewhere/components'
import { Router, OperationReadingProps, RfidReader, AppStore } from 'stylewhere/shared'
import { showToast, showToastError } from 'stylewhere/utils'
import styled from '@emotion/styled'
import { T, __ } from 'stylewhere/shared/i18n'
import { TmrItem } from 'stylewhere/api/types'
import { CollapsibleSection, Counter, InfoContainer, QualityControlPanelMassive } from 'custom/valentino/components'
import { InboundWorkstationApi, MassiveConveyorApi } from 'custom/valentino/api'
import { merchCategories } from 'custom/valentino/config'
import {
  Defect,
  DefectsTable,
  InboundWorkstationItemConfirmation,
  InboundWorkstationItemInfo,
  InboundWorkstationReportData,
} from 'custom/valentino/api/types'
import { CustomItemApi } from 'custom/valentino/api/Quality-Control/CustomItem'

interface State {
  mission?: any
  readItems: InboundWorkstationItemInfo[]
  readItemsMap: {
    [key: string]: {
      id: string
      commessa: string
      materiale: string
      tagliaColore: string
      wam: string
      epcs: string[]
    }
  }
  selectedCatMerch?: { value: string; label: string }
  loading?: boolean
  parentDefects?: Defect[]
  childDefects?: Defect[]
  selectedParentDefect?: Defect
  selectedChildDefect?: Defect
  addedDefects?: DefectsTable[]
  physicalStorage?: string
  inboundWorkstationData?: InboundWorkstationReportData
  showPageOverlayLoader?: boolean
  embeddedItems: any[]
  ignoredItems: any[]
  control_type?: string
  control_status?: string
  selectedSizeAndColor?: string
  notes?: string
  toggleClearPanel: boolean
}

export default class MassiveConveyor extends Component<OperationReadingProps<State>, State> {
  locationState = Router.getLocationState<State>(this.props)

  state: State = {
    loading: true,
    physicalStorage: 'V00',
    showPageOverlayLoader: false,
    embeddedItems: [],
    ignoredItems: [],
    readItems: [],
    readItemsMap: {},
    control_type: 'FIRST',
    toggleClearPanel: false,
  }

  materialToCheck = {}

  tableStructure = [
    {
      key: 'commessa',
      label: 'Commessa',
      value: 'commessa',
      flex: 1,
    },
    {
      key: 'materiale',
      label: 'Modello',
      value: 'materiale',
      flex: 1,
    },
    {
      key: 'size',
      label: 'Taglia/Colore',
      value: 'tagliaColore',
      flex: 1,
    },
    {
      key: 'wam',
      label: 'WAM',
      value: 'wam',
      flex: 1,
    },
    {
      key: 'readQty',
      label: 'Qta Letta',
      flex: 1,
      customRender: (item, column) => <Box center>{item.epcs.length}</Box>,
    },
  ]

  async componentDidMount() {
    RfidReader.automaticStop = false
    RfidReader.setOnTagReadCallback((tag) => this.onTagReceived(tag.epc))
  }

  onTagReceived = async (epc: string) => {
    try {
      const { mission, physicalStorage, readItemsMap } = this.state

      if (!mission?.code || !physicalStorage || !epc)
        throw new Error('Errore, dati mancanti tra missione o storage fisico o epc')

      /**** checking if is embedded tag */
      if (epc.startsWith('31')) {
        this.setState((prevState) => {
          const updatedEmbeddedItems = [...prevState.embeddedItems, epc]
          return { ...prevState, embeddedItems: updatedEmbeddedItems }
        })
        return
      }
      /************ */

      /*** item creation if the epc is not in the system but the product is retived by GS1 */
      if (!AppStore?.defaultWorkstation?.placeId)
        throw new Error('Postazione non selezionata in dashboard, selezionare una postazione per continuare')
      const items = (await CustomItemApi.batchDecode(
        [epc],
        AppStore?.defaultWorkstation?.placeId ?? AppStore.loggedUser?.place?.id ?? '',
        AppStore.loggedUser?.id ?? ''
      )) as { [tagCode: string]: TmrItem | null }
      if (!items) return

      if (Object.values(items).length === 0) throw new Error('Articolo letto non trovato')

      if (
        !items[epc] ||
        !items[epc]?.attributes ||
        !items[epc]?.attributes?.wam ||
        items[epc]?.identifiers?.length === 0
      ) {
        this.setState((prevState) => {
          const updatedIgnoredItems = [...prevState.ignoredItems, epc]
          return { ...prevState, ignoredItems: updatedIgnoredItems }
        })
        return
      }

      /************ */

      const res = await InboundWorkstationApi.getItemInfo(this.state.mission?.code, epc, physicalStorage)
      if (!res.firstSapInboundItem) throw new Error('Errore, il pezzo non ha effettuato il primo controllo qualità')

      if (Object.values(this.materialToCheck).length > 0 && !this.materialToCheck[res?.firstSapInboundItem?.matNr])
        throw new Error('Errore, materiale non corrispondente')
      else this.materialToCheck[res?.firstSapInboundItem?.matNr] = res?.firstSapInboundItem?.matNr

      const key =
        res.firstSapInboundItem.commessa +
        '_' +
        res.firstSapInboundItem.matNr +
        '_' +
        res.firstSapInboundItem.j3ASIZE +
        '_' +
        res.firstSapInboundItem.wam

      if (readItemsMap[key]) {
        readItemsMap[key].epcs.push(epc)
      } else {
        readItemsMap[key] = {
          id: key,
          commessa: res.firstSapInboundItem.commessa,
          materiale: res.firstSapInboundItem.matNr,
          tagliaColore: res.firstSapInboundItem.j3ASIZE,
          wam: res.firstSapInboundItem.wam,
          epcs: [epc],
        }
      }

      this.setState((prevState) => {
        const updatedReadItems = [...prevState.readItems, res]
        const updatedReadItemsMap = { ...prevState.readItemsMap, ...readItemsMap }
        return { ...prevState, readItems: updatedReadItems, readItemsMap: updatedReadItemsMap }
      })
    } catch (error: any) {
      this.setState((prevState) => {
        const updatedIgnoredItems = [...prevState.ignoredItems, epc]
        return { ...prevState, ignoredItems: updatedIgnoredItems }
      })
      showToastError(error.message)
    }
  }

  fetchInboundWorkstationData = async () => {
    const { mission, physicalStorage } = this.state
    try {
      if (!mission?.code) throw new Error('Compilare missione')
      this.setState({ loading: true })
      const res = await InboundWorkstationApi.getInboundWorkstationData(mission?.code, physicalStorage ?? 'V00')
      this.setState({ loading: false, inboundWorkstationData: res })
    } catch (error: any) {
      showToastError(error.message)
    }
  }

  fetchDefectsByCatMerch = async (category: string = merchCategories[0]) => {
    try {
      const res = await InboundWorkstationApi.getDefectsParentsByMerchCategory(category)
      const parentDefects: Defect[] = res.content ?? []
      this.setState({ parentDefects })
    } catch (error) {
      console.error(error)
    }
  }

  clear = () => {
    RfidReader.clear()
    this.materialToCheck = {}
    this.setState({
      readItems: [],
    })
  }

  goBack = () => {
    Router.navigate('/')
  }

  onMerchCateogrySelected = async (category: { value: string; label: string }) => {
    this.setState({ selectedCatMerch: category })
    await this.fetchDefectsByCatMerch(category.value)
  }

  onParentDefectSelected = async (defect: Defect) => {
    this.setState({ selectedParentDefect: defect })
    const res = await InboundWorkstationApi.getDefectsChildsByMerchCategory(
      this.state.selectedCatMerch?.value ?? '',
      defect.id
    )
    const childDefects: Defect[] = res.content ?? []
    this.setState({ childDefects })
  }

  onChildDefectSelected = (defect: Defect) => {
    this.setState({ selectedChildDefect: defect })
  }

  clearSelectedDefects = () => {
    this.setState({ selectedParentDefect: undefined, selectedChildDefect: undefined })
  }

  updateAddedDefects = (defects: DefectsTable[]) => {
    this.setState({ addedDefects: defects })
  }

  onMissionCodeChange = (text: string) => {
    const { mission } = this.state
    const clearData = text.length === 0

    if (clearData) this.clearAll()

    this.setState({
      mission: { ...mission, id: '3', code: text },
    })
  }

  confirmQualityControlItems = async () => {
    try {
      const {
        mission,
        readItems,
        addedDefects,
        control_status,
        control_type,
        selectedSizeAndColor,
        notes,
        physicalStorage,
      } = this.state
      RfidReader.stop()
      if (!control_type) throw new Error('Selezionare tipo controllo')
      if (!control_status) throw new Error('Selezionare stato controllo')
      //if (!selectedSizeAndColor) throw new Error('Selezionare taglia e colore')
      if (control_status === 'KO_VALDAGNO' && addedDefects?.length === 0)
        throw new Error('Selezionare almeno una causale per lo status KO')

      if (readItems.length === 0) throw new Error('Nessun articolo da confermare')

      this.setShowPageOverlayLoader(true)

      const items = {
        commands: readItems.map((readItem) => {
          const { firstSapInboundItem } = readItem
          const itemData =
            control_type === 'FIRST'
              ? { ...firstSapInboundItem, cqType: 'FIRST' }
              : { ...firstSapInboundItem, cqType: 'SECOND' }

          //populate defects
          itemData.defects = []
          addedDefects?.forEach((defect) => {
            itemData.defects?.push(defect.child)
          })
          /***** */

          //populate images
          itemData.images = []
          /***** */

          itemData.status = control_status
          itemData.sizeColor = selectedSizeAndColor ?? undefined
          itemData.notes = notes ?? ''

          const itemPayload: InboundWorkstationItemConfirmation = {
            mission: mission.code || '',
            warehouse: physicalStorage || '',
            //@ts-ignore
            item: itemData,
          }

          return itemPayload
        }),
      }
      await MassiveConveyorApi.createSapQualityControlItems(items)
      showToast({ status: 'success', description: 'Controllo qualità completato con successo' })
      this.clearAll()
    } catch (error: any) {
      showToastError(error.message || 'Errore conferma controllo qualità')
      this.setShowPageOverlayLoader(false)
    }
  }

  setShowPageOverlayLoader = (show: boolean) => {
    this.setState({ showPageOverlayLoader: show })
  }

  clearAll = () => {
    RfidReader.clear()
    this.materialToCheck = {}
    this.setState({
      readItems: [],
      selectedCatMerch: undefined,
      parentDefects: undefined,
      childDefects: undefined,
      inboundWorkstationData: undefined,
      showPageOverlayLoader: false,
      loading: false,
      selectedParentDefect: undefined,
      selectedChildDefect: undefined,
      addedDefects: undefined,
      readItemsMap: {},
      control_status: undefined,
      control_type: 'FIRST',
      embeddedItems: [],
      ignoredItems: [],
      mission: undefined,
      notes: undefined,
      selectedSizeAndColor: undefined,
      toggleClearPanel: !this.state.toggleClearPanel,
    })
  }

  render() {
    const {
      mission,
      readItems,
      selectedCatMerch,
      parentDefects,
      childDefects,
      physicalStorage,
      inboundWorkstationData,
      showPageOverlayLoader,
      embeddedItems,
      ignoredItems,
      readItemsMap,
      control_status,
      control_type,
      selectedSizeAndColor,
      toggleClearPanel,
    } = this.state
    const hideClear = (readItems?.length ?? 0) === 0
    const isAntennaButtonDisabled = !inboundWorkstationData
    const goodItems = Object.values(readItemsMap)
    const goodItemsCount = goodItems.reduce((acc, item) => acc + item.epcs.length, 0)

    return (
      <Page title={'Varco Massivo CQ'} enableEmulation>
        <Page.Content notBoxed>
          <InfoContainer>
            <FormContainer>
              <FormRow>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.mission), name: 'Mission', focus: true }}
                    index={0}
                    value={mission?.code ?? ''}
                    onChange={this.onMissionCodeChange}
                    onEnter={() => this.fetchInboundWorkstationData()}
                  />
                </FormElement>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={physicalStorage}
                    field={{ type: 'text', label: __(T.fields.physical_storage), name: 'PhysicalStorage' }}
                    index={2}
                    disabled={true}
                    value={physicalStorage}
                    onChange={() => {}}
                  />
                </FormElement>
              </FormRow>
              <FormRow>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.mission_type), name: 'MissionType' }}
                    index={1}
                    value={inboundWorkstationData?.tipoStock ?? ''}
                    disabled
                    onChange={(text: string) => this.setState({ mission: { ...mission, type: text } })}
                  />
                </FormElement>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.season), name: 'Season' }}
                    index={5}
                    disabled
                    value={inboundWorkstationData?.trilogySeason ?? ''}
                    onChange={(text: string) => this.setState({ mission: { ...mission, season: text } })}
                  />
                </FormElement>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.notification), name: 'Notification' }}
                    index={8}
                    disabled
                    value={inboundWorkstationData?.numNotifica ?? ''}
                    onChange={(text: string) => this.setState({ mission: { ...mission, notification: text } })}
                  />
                </FormElement>
              </FormRow>
              <FormRow>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.supplier), name: 'Supplier' }}
                    index={4}
                    value={inboundWorkstationData?.lifNr ?? ''}
                    disabled
                    onChange={(text: string) =>
                      this.setState({ mission: { ...mission, supplier: { ...mission.supplier, code: text } } })
                    }
                  />
                </FormElement>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.ddt), name: 'Ddt' }}
                    index={6}
                    disabled
                    value={inboundWorkstationData?.ddt ?? ''}
                    onChange={(text: string) => this.setState({ mission: { ...mission, ddt: text } })}
                  />
                </FormElement>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.ddt_date), name: 'DDtDate' }}
                    index={7}
                    disabled
                    value={inboundWorkstationData?.dataDdt ?? ''}
                    onChange={(text: string) => this.setState({ mission: { ...mission, ddt_date: text } })}
                  />
                </FormElement>
              </FormRow>
              <FormRow>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.notification_date), name: 'NotificationDate' }}
                    index={9}
                    disabled
                    value={inboundWorkstationData?.dataNotifica ?? ''}
                    onChange={(text: string) => this.setState({ mission: { ...mission, notification_date: text } })}
                  />
                </FormElement>
              </FormRow>
            </FormContainer>
            <Box center mt={10}>
              <AntennaButton
                disabled={isAntennaButtonDisabled}
                style={{ width: 400 }}
                hideClear={hideClear}
                showPendingTags={false}
                onClear={() => this.clearAll()}
              />
            </Box>
          </InfoContainer>
          <Box
            row
            style={{
              flexWrap: 'wrap',
            }}
          >
            <Box flex={3} mr={10}>
              <InfoContainer>
                <CollapsibleSection title={__(T.misc.quality_check)}>
                  <QualityControlPanelMassive
                    type="single"
                    onParentDefectSelected={this.onParentDefectSelected}
                    onChildDefectSelected={this.onChildDefectSelected}
                    clearSelectedDefects={this.clearSelectedDefects}
                    parentDefects={parentDefects}
                    childDefects={childDefects}
                    currentSelectedMerch={selectedCatMerch?.value}
                    updateAddedDefects={this.updateAddedDefects}
                    item={readItems?.[0]?.firstSapInboundItem}
                    merchCategories={merchCategories}
                    onMerchCateogrySelected={this.onMerchCateogrySelected}
                    selectedCatMerch={selectedCatMerch}
                    readItems={readItems.map((el) => el.firstSapInboundItem)}
                    onSizeAndColorSelected={(sizeAndColor) =>
                      this.setState({ selectedSizeAndColor: sizeAndColor.value })
                    }
                    onNotesChange={(notes) => this.setState({ notes })}
                    onControlTypeChange={(cp) => {
                      this.setState({ control_type: cp })
                    }}
                    onControlStatusChange={(cs) => {
                      this.setState({ control_status: cs })
                    }}
                    toggleClearComponent={toggleClearPanel}
                  />
                </CollapsibleSection>
              </InfoContainer>
            </Box>
          </Box>
          <Spacer height="20" />
          <Button
            style={{ alignSelf: 'center' }}
            title={__(T.misc.confirm)}
            onClick={this.confirmQualityControlItems}
            disabled={goodItemsCount === 0 || !control_status || !control_type}
          />
          <Spacer height="20" />
          <Box
            row
            vcenter
            center
            flexWrap
            style={{
              gap: 10,
            }}
          >
            <Counter bgColor="#1fd655" count={goodItemsCount ?? 0} text={__(T.misc.ok)} />
            <Counter bgColor="#F6C344" count={embeddedItems?.length ?? 0} text={__(T.misc.embedded)} />
            <Counter bgColor="#CCCCCC" count={ignoredItems?.length ?? 0} text={__(T.misc.ignoreds)} />
          </Box>
          <Spacer width={20} />
          <Table data={goodItems} structure={this.tableStructure} />
          {showPageOverlayLoader && <FullLoadingLayer />}
        </Page.Content>
      </Page>
    )
  }
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; // Mantiene lo spazio tra le righe
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px; // Mantiene lo spazio tra gli input nella stessa riga
  flex-wrap: wrap; // Gli elementi vanno a capo se non c'è abbastanza spazio
  justify-content: flex-start; // Allinea gli elementi all'inizio della riga
`

const FormElement = styled.div`
  flex: 1;
  min-width: 320px; // Imposta una larghezza minima per ogni elemento per garantire leggibilità
  box-sizing: border-box; // Include padding e bordo nel calcolo della larghezza
`
