import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'

interface Props {
  count: number
  text?: string
  bgColor?: string
  textColor?: string
  height?: string
  width?: string
  style?: React.CSSProperties
}

export default function Counter({ count, text, bgColor, textColor, height, width, style }: Props) {
  return (
    <CounterBox height={height} width={width} backgroundColor={bgColor} color={textColor} style={style}>
      <p>{count}</p>
      <p>{text}</p>
    </CounterBox>
  )
}

const CounterBox = styled(Box)<{ height?: string; width?: string; backgroundColor?: string; color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? height : '100px;')};
  width: ${({ width }) => (width ? width : '300px;')};
  color: ${({ color }) => (color ? color : 'white')};
  font-weight: bold;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'grey')};
  border-radius: 15px;
  font-size: 20px;
`
