import styled from '@emotion/styled'
import { format } from 'date-fns'
import { Box, Button, Modal, Spacer } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { Document, MeasurementsFields, SessionItem } from 'custom/valentino/api/types'
import ImagesGallery from './ImagesGallery'

interface Props {
  visible: boolean
  document?: Document
  sessionItems?: SessionItem[]
  measurementsFields?: MeasurementsFields[]
  onClose: () => void
}

export default function InboundWorkstationDocumentModal({
  onClose,
  visible,
  document,
  sessionItems,
  measurementsFields,
}: Props) {
  const measurements = measurementsFields?.map((m) => m.fields).flat(1)

  const renderAttributes = () => {
    return document?.attributes?.map((attribute, index) => {
      return (
        <Box row key={index} bg={index % 2 === 0 ? '#E2E2E2' : '#fff'} style={{ padding: 5 }}>
          <Label>{!!__(T.attributes[attribute.key]) ? __(T.attributes[attribute.key]) : attribute.key}</Label>
          <Value>{attribute.value}</Value>
        </Box>
      )
    })
  }

  const renderMeasurements = () => {
    if (!document?.measurements) return
    return Object.keys(document?.measurements).map((key, index) => {
      const measureField = measurements?.find((m) => m.key === key)
      return (
        <Box row key={index} bg={index % 2 === 0 ? '#E2E2E2' : '#fff'} style={{ padding: 5 }}>
          <Label>{measureField?.label}</Label>
          <Value>{document?.measurements[key]}</Value>
        </Box>
      )
    })
  }

  const getProductDescription = (item?: SessionItem) => {
    if (!item) return '---'
    const desc = item?.attributes?.find((attr) => attr.key === 'productDescription')?.value
    return desc || item?.item?.product?.description
  }

  const getDocumentStatus = () => {
    let status = '---'
    switch (document?.status) {
      case 'OK':
        status = 'APPROVATO'
        break
      case 'KO':
        status = 'DRAFT'
        break
      case '2S':
        status = '2S'
        break
      default:
        status = '---'
        break
    }
    return status
  }

  const getAttribute = (key: string) => {
    return document?.attributes?.find((attr) => attr.key === key)?.value || '---'
  }

  const getName = () => {
    const value = document?.qualityControlUser ?? document?.createdBy
    const name = value?.name
    const surname = value?.surname
    return name && surname ? `${name} ${surname}` : value?.username
  }

  const sessionItemFound = sessionItems?.find((item) => item.unlockDocument?.id === document?.id)

  return (
    <Modal onClose={onClose} visible={visible} size={'6xl'}>
      <Title>Dettaglio Documento</Title>
      <Spacer height="40" />
      <Title>Dettaglio</Title>
      <Spacer height="10" />
      <Box vcenter>
        <Box row>
          <Label>Stato</Label>
          <Value>{getDocumentStatus()}</Value>
        </Box>
        <Box row>
          <Label>Creato</Label>
          <Value>
            {!!document?.creationDate ? format(new Date(document?.creationDate), 'dd/MM/yyyy HH:mm') : '---'}
          </Value>
        </Box>
        <Box row>
          <Label>Fornitore</Label>
          <Value>{getAttribute('workerDescription')}</Value>
        </Box>
        <Box row>
          <Label>Modello</Label>
          <Value>{getAttribute('material')}</Value>
        </Box>
        <Box row>
          <Label>Descrizione Prodotto</Label>
          <Value>{getProductDescription(sessionItemFound)}</Value>
        </Box>
        <Box row>
          <Label>Ispettore</Label>
          <Value>{getName()}</Value>
        </Box>
      </Box>
      <Spacer height="30" />
      <Box row>
        <Box flex>
          <Title>Attributi</Title>
          <Spacer height="10" />
          {renderAttributes()}
        </Box>
        <Box flex>
          <Title>Misure</Title>
          <Spacer height="10" />
          {renderMeasurements()}
        </Box>
      </Box>
      <Spacer height="30" />
      <Box>
        <Title>Immagini</Title>
        <ImagesGallery images={document?.images || []} />
      </Box>
      <Spacer height="30" />
      <Box>
        <Title>Note</Title>
        <span>{document?.notes}</span>
      </Box>
      <Box center vcenter>
        <Button title={__(T.misc.close)} onClick={onClose} />
      </Box>
    </Modal>
  )
}

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`
const Value = styled.div`
  margin-left: 8px;
`

const Image = styled.img`
  margin: 5px;
  width: 200px;
  max-width: 200px;
`

const Label = styled.div`
  font-weight: bold;
  min-width: 100px;
`
