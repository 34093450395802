import { Component } from 'react'
import {
  AntennaButton,
  Box,
  Button,
  FormSchemaTextField,
  FullLoadingLayer,
  Page,
  Spacer,
  Table,
} from 'stylewhere/components'
import { Router, OperationReadingProps, RfidReader, AppStore } from 'stylewhere/shared'
import { showToast, showToastError } from 'stylewhere/utils'
import styled from '@emotion/styled'
import { T, __ } from 'stylewhere/shared/i18n'
import { Product } from 'stylewhere/api'
import { TmrItem } from 'stylewhere/api/types'
import { Counter } from 'custom/valentino/components'
import { OutboundConveyorApi } from 'custom/valentino/api'
import { OutboundConveyorResponseDto } from 'custom/valentino/api/types'
import { CustomItemApi } from 'custom/valentino/api/Quality-Control/CustomItem'

interface State {
  readItems?: {
    [key: string]: {
      id: string
      epcs: string[]
      upc: string
      product: Product
    }
  }
  physicalStorage?: string
  umgPrelievo?: string
  umgDestinazione?: string
  declarationType?: string
  mission?: string
  ddt?: string
  selectedDeclaration?: string
  declarations: {
    key: string
    label: string
    value: string
  }[]
  embeddedTags: string[]
  ignoreTags: string[]
  showPageOverlayLoader?: boolean
  okSap?: number
  rejectedQty?: number
  totalToCheckQty?: number
  errorItems?: any[]
  verifyResponse?: OutboundConveyorResponseDto
}

const tableStructure = [
  {
    key: 'wam',
    label: 'WAM',
    customRender: (item, column) => {
      return item.id ?? ''
    },
    flex: 1,
  },
  {
    key: 'model',
    label: 'Modello',
    customRender: (item, column) => {
      console.log('item', item)
      return item?.product?.material?.value ?? ''
    },
    flex: 1,
  },
  {
    key: 'size',
    label: 'Taglia/Colore',
    flex: 1,
    customRender: (item, column) => {
      return item?.product?.attributes?.tagliaColore?.value ?? ''
    },
  },
  {
    key: 'readQty',
    label: 'Qta Letta',
    customRender: (item, column) => item?.epcs?.length ?? 0,
    flex: 1,
  },
]

const errorItemsTableStructure = [
  {
    key: 'wam',
    label: 'WAM',
    customRender: (item, column) => {
      return item.wam ?? ''
    },
    flex: 1,
  },
  {
    key: 'model',
    label: 'Modello',
    customRender: (item, column) => {
      return item?.material ?? ''
    },
    flex: 1,
  },
  {
    key: 'size',
    label: 'Taglia/Colore',
    flex: 1,
    customRender: (item, column) => {
      return item?.sizeColor ?? ''
    },
  },
  {
    key: 'message',
    label: 'Message',
    customRender: (item, column) => item.message ?? '',
    flex: 1,
  },
]

export default class OutboundConveyor extends Component<OperationReadingProps<State>, State> {
  locationState = Router.getLocationState<State>(this.props)

  state: State = {
    physicalStorage: 'V00',
    declarations: [
      { key: 'B', label: 'BUONI', value: 'B' },
      { key: 'F', label: 'FALLATI', value: '1' },
      { key: 'O', label: 'BUONI OUTLET', value: '2' },
      { key: 'M', label: 'MACERO', value: 'M' },
      { key: 'R', label: 'RESO', value: 'R' },
    ],
    embeddedTags: [],
    ignoreTags: [],
    readItems: {},
  }

  componentDidMount(): void {
    RfidReader.automaticStop = false
    RfidReader.setOnTagReadCallback((tag) => this.onTagReceived(tag.epc))
  }

  initReader = async () => {
    RfidReader.setOnTagReadCallback((tag) => this.onTagReceived(tag.epc))
  }

  onTagReceived = async (epc: string) => {
    try {
      /**** checking if is embedded tag */
      if (epc.startsWith('31')) {
        this.setState((prevState) => {
          const updatedEmbeddedTags = [...prevState.embeddedTags, epc]
          return { ...prevState, embeddedTags: updatedEmbeddedTags }
        })
        return
      }
      /************ */

      /*** item creation if the epc is not in the system but the product is retived by GS1 */
      if (!AppStore?.defaultWorkstation?.placeId)
        throw new Error('Postazione non selezionata in dashboard, selezionare una postazione per continuare')
      const items = (await CustomItemApi.batchDecode(
        [epc],
        AppStore?.defaultWorkstation?.placeId ?? AppStore.loggedUser?.place?.id ?? '',
        AppStore.loggedUser?.id ?? ''
      )) as { [tagCode: string]: TmrItem | null }
      if (!items) return

      if (Object.values(items)?.length === 0) throw new Error('Articolo letto non trovato')

      if (
        !items[epc] ||
        !items[epc]?.attributes ||
        !items[epc]?.attributes?.wam ||
        items[epc]?.identifiers?.length === 0
      ) {
        this.setState((prevState) => {
          const updatedIgnoreTags = [...prevState.ignoreTags, epc]
          return { ...prevState, ignoreTags: updatedIgnoreTags }
        })
        return
      }

      /************ */

      const item: any = await OutboundConveyorApi.decodeItem(epc)
      if (!item || item.error || !item.attributes.wam || !item.product || !item.product?.code) {
        this.setState((prevState) => {
          const updatedIgnoreTags = [...prevState.ignoreTags, epc]
          return { ...prevState, ignoreTags: updatedIgnoreTags }
        })
        return
      }

      const wam = item.attributes.wam

      this.setState((prevState) => {
        const updatedReadItems = { ...prevState.readItems }

        if (!updatedReadItems[wam]) {
          updatedReadItems[wam] = { id: wam, upc: item.product.code, epcs: [epc], product: item.product }
        } else {
          updatedReadItems[wam].epcs.push(epc)
        }

        return { ...prevState, readItems: updatedReadItems }
      })
    } catch (error: any) {
      console.error(error)
      showToastError(error.message, 'Errore')
      this.setState((prevState) => {
        const updatedIgnoreTags = [...prevState.ignoreTags, epc]
        return { ...prevState, ignoreTags: updatedIgnoreTags }
      })
      return
    }
  }

  goBack = () => {
    Router.navigate('/')
  }

  renderDeclarations = () => {
    const { declarations, selectedDeclaration } = this.state
    return declarations.map((declaration) => (
      <DeclarationBox
        key={declaration.key}
        selected={selectedDeclaration === declaration.value}
        onClick={() => this.setState({ selectedDeclaration: declaration.value })}
      >
        <Title>{declaration.key}</Title>
        <SubTitle>{declaration.label}</SubTitle>
      </DeclarationBox>
    ))
  }

  resetReads = () => {
    RfidReader.clear()
    this.setState({ readItems: undefined, embeddedTags: [], ignoreTags: [] })
  }

  verifyReads = async () => {
    try {
      this.setShowPageOverlayLoader(true)
      const { readItems, selectedDeclaration, physicalStorage, declarationType, umgDestinazione, umgPrelievo } =
        this.state
      if (!readItems) throw new Error('Non ci sono letture da verificare')
      if (!selectedDeclaration) throw new Error('Selezionare una dichiarazione')
      if (!physicalStorage) throw new Error('Inserire un magazzino fisico')
      if (!umgPrelievo) throw new Error('Inserire un umg di prelievo')
      if (!umgDestinazione) throw new Error('Inserire un umg di destinazione')

      const res: any = await OutboundConveyorApi.verifyReads({
        items: this.getItemsFromReads(),
        warehouse: physicalStorage,
        umgIn: umgPrelievo,
        umgOut: umgDestinazione,
        declarationType: selectedDeclaration,
      })
      if (!res || res.errorCode) throw new Error('Errore durante la verifica delle letture')
      if (res.messageType === 'E') {
        showToastError(res.message, 'Errore')
      }

      const errorItems = res?.sapOutboundItems?.filter((item: any) => item.messageType === 'E')

      this.setState({
        ddt: res.ddt,
        mission: res.mission,
        okSap: Number(res.totalCheckedQty) - Number(res.rejectedQty),
        rejectedQty: Number(res.rejectedQty),
        totalToCheckQty: Number(res.totalToCheckQty),
        errorItems,
        verifyResponse: res,
      })
      showToast({
        status: 'success',
        description: 'Letture verificate con successo',
      })
      this.setShowPageOverlayLoader(false)
    } catch (error: any) {
      this.setShowPageOverlayLoader(false)
      showToastError(error.message, 'Errore')
    }
  }

  async confirmReads() {
    try {
      this.setShowPageOverlayLoader(true)
      const { readItems, selectedDeclaration, physicalStorage, umgDestinazione, umgPrelievo, verifyResponse } =
        this.state
      if (!readItems) throw new Error('Non ci sono letture da confermare')
      if (!selectedDeclaration) throw new Error('Selezionare una dichiarazione')
      if (!physicalStorage) throw new Error('Inserire un magazzino fisico')
      if (!umgPrelievo) throw new Error('Inserire un umg di prelievo')
      if (!umgDestinazione) throw new Error('Inserire un umg di destinazione')
      if (!verifyResponse) throw new Error('Verifica le letture prima di confermarle')

      const items = this.getItemsFromReads()

      const payloadToSend = {
        ...verifyResponse,
        umgIn: umgPrelievo,
        umgOut: umgDestinazione,
        warehouse: physicalStorage,
        declarationType: selectedDeclaration,
        items:
          verifyResponse?.sapOutboundItems
            ?.filter((itm) => itm?.messageType !== 'E')
            .map((el) => ({
              ...el,
              ...verifyResponse,
            })) ?? [],
      }
      if (!items || items.length === 0) throw new Error('Non ci sono letture da confermare')

      const res: any = await OutboundConveyorApi.confirmReads(payloadToSend)
      if (!res || res.errorCode) throw new Error('Errore durante la conferma delle letture')

      showToast({
        status: 'success',
        description: 'Letture confermate con successo',
      })
      this.clearAll()
      this.setShowPageOverlayLoader(false)
    } catch (error: any) {
      this.setShowPageOverlayLoader(false)
      showToastError(error.message, 'Errore')
    }
  }

  clearAll = () => {
    RfidReader.clear()
    this.setState({
      readItems: undefined,
      embeddedTags: [],
      ignoreTags: [],
      ddt: undefined,
      mission: undefined,
      okSap: undefined,
      rejectedQty: undefined,
      totalToCheckQty: undefined,
      errorItems: undefined,
      verifyResponse: undefined,
      selectedDeclaration: undefined,
      umgDestinazione: undefined,
      umgPrelievo: undefined,
      declarationType: undefined,
    })
  }

  getItemsFromReads = () => {
    const { readItems } = this.state
    if (!readItems) return []
    const items: any = []
    Object.keys(readItems).map((key) => {
      readItems[key].epcs.map((epc) => {
        items.push({
          wam: key,
          quantity: 1,
          epc,
        })
      })
    })
    return items
  }

  setShowPageOverlayLoader = (show: boolean) => {
    this.setState({ showPageOverlayLoader: show })
  }

  render() {
    const {
      mission,
      physicalStorage,
      umgPrelievo,
      umgDestinazione,
      declarationType,
      ddt,
      readItems,
      embeddedTags,
      ignoreTags,
      showPageOverlayLoader,
      okSap,
      rejectedQty,
      totalToCheckQty,
    } = this.state
    const hideClear =
      !readItems || Object.keys(readItems).length === 0 || embeddedTags.length === 0 || ignoreTags.length === 0

    const okRfidItems = Object.values(readItems ?? {}).reduce((acc, item) => acc + item.epcs.length, 0)
    const confirmIsDisabled = okRfidItems <= 0
    return (
      <Page title={'Varco uscita CQ'} enableEmulation>
        <Page.Content notBoxed>
          <InfoContainer>
            <FormContainer>
              <FormColumn>
                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: 'Magazzino Fisico', name: 'Physical Storage', focus: true }}
                  index={0}
                  disabled
                  value={physicalStorage}
                  onChange={(text: string) => this.setState({ physicalStorage: text })}
                />
              </FormColumn>
              <FormColumn>
                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', focus: true, label: 'Umg Prelievo', name: 'Umg Prelievo' }}
                  index={1}
                  value={umgPrelievo ?? ''}
                  onChange={(text: string) => this.setState({ umgPrelievo: text })}
                />
                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: __(T.fields.ddt), name: 'Ddt' }}
                  index={5}
                  disabled
                  value={ddt}
                  onChange={(text: string) => {}}
                />
              </FormColumn>
              <FormColumn>
                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: 'Umg Destino', name: 'Umg Destino' }}
                  index={2}
                  value={umgDestinazione ?? ''}
                  onChange={(text: string) => this.setState({ umgDestinazione: text })}
                />
                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: 'Missione', name: 'mission' }}
                  index={4}
                  disabled
                  value={mission}
                  onChange={(text: string) => this.setState({ mission: text })}
                />
              </FormColumn>
            </FormContainer>
            <Box row vcenter center style={{ gap: 20 }}>
              {this.renderDeclarations()}
            </Box>
            <Spacer height={'20'} />
            <Box center>
              <AntennaButton
                style={{ width: 400 }}
                hideClear={hideClear}
                showPendingTags={false}
                onClear={this.resetReads}
              />
            </Box>
          </InfoContainer>
          <Spacer height={'30'} />
          <InfoContainer>
            <Box row vcenter center>
              <Button
                variant="secondary"
                disabled={okRfidItems <= 0}
                title={__(T.misc.verify)}
                onClick={this.verifyReads}
              />
              <Spacer width={10} />
              <Button disabled={confirmIsDisabled} title={__(T.misc.confirm)} onClick={() => this.confirmReads()} />
            </Box>
            <Spacer height={'20'} />
            <Box
              row
              vcenter
              center
              flexWrap
              style={{
                gap: 10,
              }}
            >
              <Counter bgColor="#1fd655" count={okRfidItems} text={'OK RFID'} />
              <Counter bgColor="#1fd655" count={okSap ?? 0} text={'OK SAP'} />
              <Counter bgColor="#f03535" count={rejectedQty ?? 0} text={__('custom.rejected')} />
              <Counter bgColor="#F6C344" count={totalToCheckQty ?? 0} text={__('custom.tot_to_check')} />
              <Counter bgColor="#F6C344" count={embeddedTags.length} text={__(T.misc.embedded)} />
              <Counter bgColor="#CCCCCC" count={ignoreTags.length} text={__(T.misc.ignoreds)} />
            </Box>
            <Spacer width={20} />
            <TableTitle>ITEMS LETTI</TableTitle>
            <Spacer height={'10'} />
            <Table data={Object.values(readItems ?? {})} structure={tableStructure} />
            <Spacer height={'50'} />
            <TableTitle>MESSAGGI SAP</TableTitle>
            <Spacer height={'10'} />
            <Table data={this.state.errorItems ?? []} structure={errorItemsTableStructure} />
          </InfoContainer>
          {showPageOverlayLoader && <FullLoadingLayer />}
        </Page.Content>
      </Page>
    )
  }
}

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px; // Questo aggiunge uno spazio sia orizzontale che verticale tra gli elementi
  justify-content: flex-start; // Modificato da space-between a flex-start per evitare che gli elementi si estendano
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  background-color: 'red';
`

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; // Mantiene lo spazio tra gli elementi verticali
  flex: 1; // Questo permette alle colonne di riempire lo spazio disponibile
  min-width: 200px; // Assegna una larghezza minima per la colonna considerando il gap
  box-sizing: border-box; // Assicura che il padding e il bordo siano inclusi nella larghezza
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
`

const Title = styled.span`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
`

const TableTitle = styled.span`
  font-size: 28px;
  font-weight: 700;
  margin-top: 10px;
`

const SubTitle = styled.span`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
`

const Description = styled.label`
  font-weight: 500;
  padding: 25px 15px;
  font-size: 21px;
  text-align: center;
  opacity: 0.5;
`

const DeclarationBox = styled.div<{ selected?: boolean }>`
  height: 150px;
  width: 150px;
  background-color: ${(props) => (props.selected ? '#5887FF' : '#50514f')};
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  border-radius: 20px;
`
