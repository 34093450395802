import { useEffect, useState } from 'react'
import { showToastError } from 'stylewhere/utils'
import { Image as ChakraImage, Skeleton } from '@chakra-ui/react'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { CqImageApi } from 'custom/valentino/api/Quality-Control/CqImage'

interface Props {
  images: string[]
  imageStyle?: React.CSSProperties
}

export default function ImagesGallery({ images, imageStyle }: Props) {
  const [loading, setLoading] = useState(true)
  const [imagesUrls, setImagesUrls] = useState<string[]>([])

  useEffect(() => {
    async function fetchImages() {
      try {
        const urls = await CqImageApi.getImages(images)
        setImagesUrls(urls)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        showToastError('Error fetching images')
      }
    }
    fetchImages()
  }, [images])

  const renderImageLoading = () => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {images?.map((image, index) => (
          <Skeleton style={{ margin: 5, width: 200, height: 200, maxWidth: 200 }}></Skeleton>
        ))}
      </div>
    )
  }

  if (loading) {
    return renderImageLoading()
  } else
    return (
      <PhotoProvider>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {imagesUrls?.map((image) => (
            <PhotoView src={image} key={image}>
              <ChakraImage
                style={{ margin: 5, width: 200, maxWidth: 200, ...imageStyle }}
                src={image}
                //fallbackSrc="https://via.placeholder.com/150"
              />
            </PhotoView>
          ))}
        </div>
      </PhotoProvider>
    )
}
